import TextList from '@pretto/bricks/website/shared/components/TextList'

import PropTypes from 'prop-types'

import * as S from '../styles'

const List = ({ as, children }) => (
  <S.Block>
    <TextList as={as}>{children}</TextList>
  </S.Block>
)

List.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default List
